<template>
  <div class="header_page">
    <img @click="redirectHome" src="../../../../public/media/logo-nen-tim.png" class="logo-web" alt="edutalk-logo">
    <div class="header_page__action">
      <div class="element-icon">
        <i class="el-icon-search header_page__icon"></i>
        <div class="element-icon__child-search">
          <input v-model="keyword" class="input-search" @input="searchHonor" placeholder="Tìm kiếm"/>
        </div>
      </div>
      <img @click="redirectHome" src="../../../../public/media/logo-nen-tim.png" class="logo-mb" alt="edutalk-logo">
      <div class="element-icon">
        <i class="el-icon-menu header_page__icon"></i>
        <div class="element-icon__child">
          <div style="display: flex; flex-direction: column; gap: 15px; justify-content: center; align-items: center">
            <button
                class="btn-select__year"
                v-for="year in listDate"
                :key="year"
                @click="queryDataToYear(year)"
            >{{year}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  data() {
    return {
      timeNow: +moment(new Date()).format('YYYY'),
      listDate: [],
      year: null,
      keyword: ''
    }
  },
  mounted() {
    this.getDate()
  },
  methods: {
    getDate() {
      for (let i = 2023; i <= this.timeNow; i++) {
        this.listDate.push(i)
      }
    },
    redirectHome() {
      this.$router.push({
        name: "dashboard",
      })
    },
    queryDataToYear(year) {
      this.$emit('queryDataToYear', year);
      this.year = year
    },
    searchHonor() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit('searchHonor', this.keyword);
      }, 500);
    }
  }
}
</script>

<style lang="css" scoped>
  .header_page {
    display: flex;
    justify-content: space-between;
    padding: 15px 200px;
    background: #3D2C7C;
    height: 75px;
  }
  .logo-mb {
    display: none;
  }
  .logo-web {
    display: block;
  }
  .header_page__action {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 28px;
  }
  .header_page__icon {
    font-size: 30px;
    color: #AA8CFF;
    cursor: pointer;
  }
  .element-icon {
    position: relative;
  }
  .element-icon__child {
    z-index: 99999999;
    display: none;
    position: absolute;
    top: 100%;
    padding-top: 30px;
    right: 0;
    background-color: transparent;
    animation: displaySearch 0.5s ease-in-out;
  }

  .input-search {
    border: none;
    outline: none;
    background: #AA8CFF;
    color: white;
    border-radius: 9999px;
    padding: 7px 14px;
    min-width: 250px;
    margin-right: 40px;
  }

  .element-icon__child-search {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    animation: displaySearch 0.5s ease-in-out;
  }

  .element-icon:hover .element-icon__child {
    display: block;
  }

  .element-icon:not(:hover) .element-icon__child {
    display: none;
  }

  .element-icon:hover .element-icon__child-search {
    display: block;
  }
  .element-icon:not(:hover) .element-icon__child-search {
    display: none;
  }

  .btn-select__year {
    border-radius: 9999px;
    padding: 5px 40px;
    font-size: 20px;
    color: #503694;
    border: 1px solid #EBD4A4;
    background: #AA8CFF;
  }

  @keyframes displaySearch {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media only screen and (max-width: 767px) {
    .header_page {
      padding: 15px 40px;
      width: 100%;
    }
    .logo-web {
      display: none;
    }
    .logo-mb {
      display: block;
      height: 35px;
      width: auto;
    }
    .header_page__action {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
    .element-icon__child-search {
      right: 0;
      top: 55px;
      z-index: 9999;
    }
    .element-icon__child {
      left: 65px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .header_page {
      padding: 15px 60px;
    }
  }
</style>