<template>
  <div style="margin-top: 50px">
      <h2 class="title">{{ title }}</h2>
      <p v-if="description" class="description">{{ description }}</p>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: null
    },
  }
}
</script>

<style lang="css" scoped>
  .title {
    text-align: center;
    text-transform: uppercase;
    color: #FCB813;
    font-size: 50px;
    font-weight: bold;
  }
  .description {
    text-align: center;
    color: #FFFFFF;
    font-size: 20px;
  }
  @media only screen and (max-width: 767px) {
    .title {
      padding: 20px 40px;
      font-size: 25px;
    }
    .description {
      font-size: 16px;
    }
  }
</style>